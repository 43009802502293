import React from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from './Routing/AsyncComponent'
import AppliedRoute from './Routing/AppliedRoute'

const AsyncHome = asyncComponent(() => import('./Pages/Home'))
const AsyncNotFound = asyncComponent(() => import('./Pages/NotFound'))


export default ({ childProps }) =>
  <Switch>
    <AppliedRoute 
      path='/'
      exact
      component={AsyncHome} 
      props={childProps}
    />
    <Route component={AsyncNotFound} />
  </Switch>