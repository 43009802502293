  
import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'

import './Navigation.css'

export default function Navigation(props) {

  return (
    <Navbar id='navbar' expand='lg' scrolling='true' fixed='top' collapseOnSelect='true' >
      <Navbar.Brand href='/' className="links brand">
        {/* <img
          alt=''
          src='/logo.svg'
          width='30'
          height='30'
          className='d-inline-block align-top'
        />{' '} */}
        Dane Lindell
      </Navbar.Brand>
      <Navbar.Toggle 
      // onClick={()=> { 
        // document.getElementById('navbar').style.backgroundColor = 'lightgray'
        // let links = document.getElementsByClassName('links')
        // for (let i = 0; i < links.length; i++) {
        //   links[i].style.color = 'black';
        // }

        // let toggler = document.getElementsByClassName('navbar-toggler')
        // for (let i = 0; i < toggler.length; i++) {
        //   toggler[i].style.borderColor = 'black';
        // }

        // let toggle = document.getElementsByClassName('navbar-toggler-icon')
        // for (let i = 0; i < toggle.length; i++) {
        //   toggle[i].style.backgroundImage = 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'30\' height=\'30\' viewBox=\'0 0 30 30\'%3e%3cpath stroke=\'rgba(0, 0, 0, 1)\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' stroke-width=\'2\' d=\'M4 7h22M4 15h22M4 23h22\'/%3e%3c/svg%3e")'
        // }
      // }} 
      id='toggler' aria-controls='basic-navbar-nav'/>
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='ml-auto justify-content-end'>
          <Nav.Link href='/#about' className="links">about</Nav.Link>
          <Nav.Link href='https://blog.danelindell.com' className="links">blog</Nav.Link>
          <Nav.Link href='/#work' className="links">work</Nav.Link>
          <Nav.Link href='/#contact' className="links">contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}