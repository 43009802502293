import React, {useState, useEffect} from 'react'
import Div100vh from 'react-div-100vh'
import { BrowserRouter as Router } from 'react-router-dom'
 
import Routes from './Routes'
import Navigation from './Components/Navigation'

import './App.css'

function App() {
  const [prevScroll, setPrevScroll] = useState(0)
  const [currentScroll, setCurrentScroll] = useState(0)

  function scrolling() {
    setCurrentScroll(window.pageYOffset)
    if(currentScroll < 100) {
      document.getElementById('navbar').style.top = '0'
      document.getElementById('navbar').style.backgroundColor = 'transparent'
      document.getElementsByClassName('navbar-toggler-icon')
     
      let toggle = document.getElementsByClassName('navbar-toggler-icon')
      for (let i = 0; i < toggle.length; i++) {
        toggle[i].style.backgroundImage = 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'30\' height=\'30\' viewBox=\'0 0 30 30\'%3e%3cpath stroke=\'rgba(255, 255, 255, 1)\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' stroke-width=\'2\' d=\'M4 7h22M4 15h22M4 23h22\'/%3e%3c/svg%3e")'
      }

      let toggler = document.getElementsByClassName('navbar-toggler')
      for (let i = 0; i < toggler.length; i++) {
        toggler[i].style.borderColor = 'white';
      }
     
      let links = document.getElementsByClassName('links')
      for (let i = 0; i < links.length; i++) {
        links[i].style.color = 'white';
      }
    } else if(prevScroll >= currentScroll) {
      document.getElementById('navbar').style.top = '0'
      document.getElementById('navbar').style.opacity = '.85'
      document.getElementById('navbar').style.backgroundColor = 'lightgray'
      
      let toggle = document.getElementsByClassName('navbar-toggler-icon')
      for (let i = 0; i < toggle.length; i++) {
        toggle[i].style.backgroundImage = 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'30\' height=\'30\' viewBox=\'0 0 30 30\'%3e%3cpath stroke=\'rgba(0, 0, 0, 1)\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' stroke-width=\'2\' d=\'M4 7h22M4 15h22M4 23h22\'/%3e%3c/svg%3e")'
      }

      let toggler = document.getElementsByClassName('navbar-toggler')
      for (let i = 0; i < toggler.length; i++) {
        toggler[i].style.borderColor = 'black';
      }

      let links = document.getElementsByClassName('links')
      for (let i = 0; i < links.length; i++) {
        links[i].style.color = 'black';
      }
    } else {
      document.getElementById('navbar').style.top = '-6rem'
      document.getElementById('navbar').style.backgroundColor = 'lightgray'
    }
    setPrevScroll(currentScroll)
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', scrolling)
    }
    watchScroll()
    return () => {
      window.removeEventListener('scroll', scrolling)
    }
  })

  return (
    <div className='App'>
      <Router>
        <Div100vh>
          <Navigation />
          <Routes />
        </Div100vh>
      </Router>
    </div>
  )
}

export default App